<template>
  <div>
    <validation-observer ref="simpleRules">
      <form-wizard
        color="#28c76f"
        :title="null"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        skip-button-text="Skip"
        class="steps-transparent mb-3"
        @on-complete="addHatchery()"
      >
        <!-- Hatchery detail tab -->
        <tab-content title="Hatchery Details" icon="feather icon-file-text">
          <b-row>
            <b-col cols="12" class="mb-2">
              <div class="d-flex">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="24"
                  class="mr-2"
                  @click="goBack"
                />

                <div>
                  <h5 class="mb-0">Hatchery Details</h5>
                  <small class="text-muted">
                    Enter Your Hatchery Details.
                  </small>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group label="Hatch Name" label-for="i-username">
                <validation-provider
                  #default="{ errors }"
                  name="Hatch Name"
                  rules="required"
                >
                  <b-form-input
                    name="hatchery_name"
                    v-model="hatcheryData.hatchery_name"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mb-2 mt-5">
              <h5 class="mb-0">Address Information</h5>
            </b-col>
            <b-col md="12">
              <b-form-group label="Address" label-for="address">
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-textarea
                    name="address"
                    v-model="hatcheryData.address"
                    placeholder=""
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="State" label-for="state">
                <v-select
                  v-model="hatcheryData.selectedState"
                  :options="stateList"
                  value-field="id"
                  label="state_name"
                  @input="getDistrict"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="District" label-for="district">
                <v-select
                  v-model="hatcheryData.selectedDistrict"
                  :options="districtList"
                  value-field="id"
                  label="district_name"
                  @input="getCity"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="City" label-for="city">
                <v-select
                  v-model="hatcheryData.selectedCity"
                  :options="cityList"
                  label="city_name"
                  value-field="city_code"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Pincode" label-for="pincode">
                <validation-provider
                  #default="{ errors }"
                  name="Pincode"
                  rules="required"
                >
                  <b-form-input
                    name="pincode"
                    type="text"
                    v-model="hatcheryData.pin_code"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>

        <!-- Assign USer -->
        <tab-content title="Assign User" icon="feather icon-map-pin">
          <div class="ml-1 mb-2">
            <b-row
              v-for="(item, index) in hatcheryData.assign_user"
              :id="item.id"
              :key="index"
              ref="row"
            >
              <!-- Roles -->
              <b-col md="3">
                <b-form-group label="Roles" label-for="Roles">
                  <v-select
                    label="selected_role"
                    :id="`selectRole_${index}`"
                    v-model="hatcheryData.assign_user[index].role"
                    :reduce="(e) => e.slug"
                    :options="companyRoles"
                    :getOptionLabel="roleLabel"
                    :searchable="true"
                    @input="getSelectedRolesName($event, index)"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
              </b-col>

              <!-- User select -->

              <b-col md="3">
                <b-form-group label="Users" label-for="Users">
                  <v-select
                    label="selected_user"
                    :id="`selectUser_${index}`"
                    v-model="hatcheryData.assign_user[index].user"
                    :reduce="(e) => e.id"
                    :options="userList[index]"
                    :getOptionLabel="userLabel"
                    :searchable="true"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-if="hatcheryData.assign_user.length > 1"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeatAgain"
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </tab-content>
      </form-wizard>
    </validation-observer>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import hatcheryUpdate from "@/libs/hatcheryUpdate";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTabs,
  BTab,
  BCardText,
  BForm,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    BFormTextarea,
    BButton,
    BTabs,
    BTab,
    BCardText,

    BForm,
    BFormGroup,

    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          id: 0,
          prevHeight: 0,
        },
      ],
      companyId: null,
      selectRole: null,
      selectName: null,
      roleWiseList: null,
      nextTodoId: 2,
      stateList: [],
      districtList: [],
      cityList: [],
      //   selectedState: null,
      //   selectedDistrict: null,
      //   selectedCity: null,
      userList: [],
      company_id: null,
      companyRoles: [],
      hatcheryData: {
        hatchery_name: null,
        pin_code: null,
        address: null,
        selectedState: null,
        selectedDistrict: null,
        selectedCity: null,
        assign_user: [
          {
            role: null,
            name: null,
          },
        ],
      },
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    this.companyId = this.$route.params.companyId;
    window.addEventListener("resize", this.initTrHeight);
    this.getState();
    this.getSelectedRole();
  },

  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    roleLabel(option) {
      return option.name;
    },
    userLabel(option) {
      return option.name;
    },
    getSelectedRole() {
      let companyId = this.companyId;
      let url = "";
      if (companyId) {
        url = `web/company/${companyId}/roles`;
      } else {
        url = `web/company/roles`;
      }
      // make api call to get list of role
      axiosIns
        .get(url)
        .then((response) => {
          this.companyRoles = response.data.companyRoles;
        })
        .catch((error) => {
          error;
        });
    },
    getSelectedRolesName(slug, index) {
      let companyId = this.companyId;
      let url = "";
      if (companyId) {
        url = `web/company/${companyId}/getUser/${slug}`;
      } else {
        url = `web/getRoleUser/${slug}`;
      }
      axiosIns
        .get(url)
        .then((response) => {
          debugger;
          this.$set(this.userList, index, response.data.unAssignedUsers);
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    repeatAgain() {
      let newRole = {
        role: null,
        name: null,
      };
      this.hatcheryData.assign_user.push(newRole);

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.hatcheryData.assign_user.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
    },
    getState() {
      axiosIns
        .get(`web/get-state-list`)
        .then((response) => {
          this.stateList = response.data.state_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getDistrict(event) {
      axiosIns
        .get(`web/get-district-list?state_id=${event.id}`)
        .then((response) => {
          this.districtList = response.data.districts_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getCity(event) {
      axiosIns
        .get(`web/get-city-list?district_id=${event.id}`)
        .then((response) => {
          this.cityList = response.data.city_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    addHatchery(e) {
      // e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          let form_data = this.objectToFormData(this.hatcheryData, formData);
          let companyId = this.companyId;
          let url = "";
          if (companyId) {
            url = `web/company/${companyId}/add-hatchery`;
          } else {
            url = `web/add-hatchery`;
          }
          axiosIns
            .post(url, form_data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  // title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Added Successfully `,
                },
              });
              hatcheryUpdate.$emit("hatcheryUpdateEvent", companyId);
              this.$router.push({
                path: `/apps/hatchery/list`,
              });
            })
            .catch((error) => {
              const data = error.response.data.errors;
              // (data, "data");
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  // title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.role-button {
  background-color: rgb(40, 199, 111);
  border-color: rgb(40, 199, 111);
  color: white;
  width: 170px;
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
@import "@core/scss/vue/pages/nav-role.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
